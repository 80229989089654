.signatureCanvas {
    border: 1px solid black;
    width: 100%;
    min-height: 500px;
  }
 .popup-content {
    margin: auto;
    background: rgb(201, 201, 201);
    width: 50%;
    padding: 5px;
  }
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }