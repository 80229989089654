/* From uiverse.io by @kirzin */
button.play {
    text-decoration: none;
    position: static;
    border: none;
    font-size: 14px;
    font-family: inherit;
    color: #fff;
    width: 9em;
    height: 3em;
    line-height: 2em;
    text-align: center;
    background: linear-gradient(90deg,#03a9f4,#f441a5,#ffeb3b,#03a9f4);
    background-size: 300%;
    border-radius: 30px;
    z-index: 1;
   }
   
   button.play:hover {
    animation: ani 8s linear infinite;
    border: none;
   }
   
   @keyframes ani {
    0% {
     background-position: 0%;
    }
   
    100% {
     background-position: 400%;
    }
   }
   
   button.play:before {
    content: '';
    position: static;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg,#03a9f4,#f441a5,#ffeb3b,#03a9f4);
    background-size: 400%;
    border-radius: 35px;
    transition: 1s;
   }
   
   button.play:hover::before {
    filter: blur(20px);
   }
   
   button.play:active {
    background: linear-gradient(32deg,#03a9f4,#f441a5,#ffeb3b,#03a9f4);
   }